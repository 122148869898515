:root{
    --negro:#231f20;
    --active:yellow;
    --morado:#d83484;
    --rojo:#e00025;
    --azul:#02abec;
    --gris-fondo:#dddddd;
    --gris-fuerte:#333333;
    --texto-cuerpo:#666666;
}

body{font-family: 'Work Sans';min-width: 300px;}

h1{ padding-bottom: 30px; font-weight: bold; }
b{ white-space:nowrap; }
i{ color:#666666; }

input.error, label.error{
    color:rgb(192, 188, 188);
    font-style: italic;
}
.enlace, .enlace:hover{
    color:#e00025;
}

.enlace_titulo, .enlace_titulo:hover {
    transition: color 0.2s;
    color: #231f20;
}
.enlace_titulo:hover {
    color: rgb(107, 107, 107);
    transition: color 0.2s;
    text-decoration: none;
}

.enlace_titulo_white, .enlace_titulo_white :hover {
    transition: color 0.2s;
    color: white;
}
.enlace_titulo_white:hover {
    color: rgb(107, 107, 107);
    transition: color 0.2s;
    text-decoration: none;
}

span.destacado{
    font-weight: bold;
    font-size: 22px;
    line-height: 1em;
}

/* HEADER */
header#top, #top .flex-fill{
    opacity: 1;
    z-index: 9999999;
    padding: 0;
    background-color: #231f20;
}
@media(min-width:480px){
    .navbar-menu {
        justify-content: flex-end!important;
    }
}

.dropdown-menu{
    margin: 0;
    border:0;
}

.dropdown-item{
    color:white;
}

.dropdown-item.active,.dropdown-item:active, .dropdown-item:focus,.dropdown-item:hover {
    background-color: black;
    color: white;
}


header#top .navbar-brand{padding:5px 0 5px;}
header#top nav .brand-logo{
    width: 157px;
}
header#top .navbar{padding: 0px;}
header#top nav li a{margin-left: 15px;margin-right: 15px;}
header#top nav li a.dropdown-item{margin: 0;}

header#top nav li a.dropdown-item:not(:last-child)  {
    border-bottom: 1px solid rgba(255, 250, 250, 0.19);
}


header#top nav li a.dropdown-item:last-child  {
    border-bottom: 1px solid white;
}

@media(min-width:992px){
    header#top nav li a.dropdown-item:last-child  {
        border-bottom: 0;
    }
    
}


header#top .dropdown-menu {background-color: #231f20;padding: 0;/* border-top: 1px solid white; */}
@media(min-width:992px){
    header#top .dropdown-menu {
        left: 50%;transform: translateX(-50%); 
    }
}

@media(min-width:992px){
    #navbarToggler a.nav-link.dropdown-toggle{border-bottom: 0px }
}

.dropdown-menu .dropdown-item{text-align:center;}

@media screen and (max-width:991px){
    /*#navbarToggler a.nav-link.dropdown-toggle{border-bottom: 1px solid lightgrey;}*/
    .dropdown-menu .dropdown-item{text-align:center;}
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        /* left: -15px; */
        /* left: -50%; */
        /* right: -50%; */
        margin: 0 auto;
    }
}
@media screen and (min-width:992px) and (max-width:1199px){
    header#top nav .nav-item{
        margin-left: 7px;
        margin-right: 7px;
    }
}
@media screen and (max-width:1199px){
    header#top .nav-link{
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

header#top nav button ,header#top nav button:focus{
    justify-self: flex-end!important;
    outline: 0;
    border: 0;
}

#top.header-nav {
    position: fixed;
    width: 100%;
}

/*video iframe*/
.embed-container {
    margin: 20px 10px 20px 10px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.embed-container video {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}


/*clase para ocultar nav durante scroll*/
.scrollUp{
    transform: translateY(-100px);
    transition: 0.3s ease-in-out;
}

header#top nav  .nav-link, header#top nav .navbar-toggler{color: white;justify-self: flex-end!important;}

.navbar-nav > .nav-item > a:after{ 
    border: none;
}

@media screen and (max-width:991px) {
    header#top nav .navbar-collapse .nav-item {
        background-color:#231f20;
        color: white;
        border-bottom: 1px solid lightgrey;
        border-left: 0;
        border-right: 0;
    }
    header#top nav .navbar-collapse .nav-item:not(:last-child) {border-bottom: 0;}
    header#top nav .navbar-collapse .nav-item:hover {color: white;background-color: black;}
    header#top nav .navbar-collapse li :hover {color: white;}
    header#top .navbar-collapse li .nav-item {font-weight: bold;}
    .navbar-items-container{ width: 100%; }
    #navbarToggler a.nav-link {border-bottom: 1px solid white;}
    header#top nav .navbar-collapse .nav-item:first-child {
        border-top: 1px solid white;
    }

    .column-image img{ padding: 0 ; }
    #navbarToggler{padding: 0;}
    header#top .container{ max-width: 100%; }
}

@media screen and (min-width:992px){
    .mobile-container{ width: 200px; }
    .navbar-items-container{ padding: 10px; }
}

.nav-div {text-align: center;color:black}
.header-nav .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {color: black;}

@media(max-width:991px){
    .header-nav .navbar-light .navbar-nav .active>.nav-link{ background-color: rgb(80, 80, 80); }
}


.header-nav .navbar-brand {margin-right: 0;color: white;font-weight: bold;}
.header-nav .navbar-brand a {color: black;text-decoration: none;}
.navbar-brand .brand {font-size: var(--font-s-navbar-title);font-weight: 900;}
.navbar-brand .brand,
.nav-bottom .brand {margin: 0;font-family: 'Patua One', cursive;}

@media screen and (max-width:480px) {
	#tu-info {display: none;}
}
@media (min-width: 992px) {
    .navbar-expand-lg {flex-flow: column;}
    
    /*linea inferior*/
    .navbar-nav > .nav-item > a:after{  
        display: block;  
        bottom:0px; 
        left: 0;
        content:"";
        border-bottom: 1px solid red;
        width: 60%;
        text-align: center;
        margin: 0 auto;
        transform: scaleX(0);
        transition:  .3s ease-out,border-color .3s ease-out;
    }
    .navbar-nav > .nav-item > a:hover:after{
        transform: scaleX(1);
        transition:  .3s ease-in,border-color .3s ease-out;
    }
    .navbar-nav > .nav-item.active > .nav-link:after{  
        display: block;  
        bottom:0px; 
        left: 0;
        content:"";
        border-bottom: 1px solid red;
        width: 60%;
        text-align: center;
        margin: 0 auto;
        transform: scaleX(1);
    }

    .dropdown-toggle:after {
        border:0px;
    }

    .navbar-nav > .nav-item.active > .dropdown-toggle:after{ 
        border-bottom: 1px solid red;
    }
    
    
}

.nav-info {font-size: 11px;font-weight: bold;color: black;}

/*simbolo separacion barra vertical - fecha | hora */
.navbar .nav-info div:not(:first-child):before {margin: 5px;content: "|";color: var(--separator);}
.nav-info {display: flex;flex-flow: row;justify-content: center;}

/* --- social buttons --- */
.social_buttons {padding: 10px;}
.social_buttons a {padding: 5px 7px 5px 7px;color: white;}
.social_buttons a:hover {color: black;}
.social_buttons a .fa-user-plus:hover {color: white;}
.social_buttons #toggle-search-btn {border-left: 1px solid white;}
.social .fab:before{color:white!important;}
.social .btn-instagram{
background: #d6249f;
background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}
.social .btn-facebook{
    background-color: cornflowerblue;
}
.social .btn-twitter{
    background-color: deepskyblue;
}
ul.social-network {
list-style: none; 
display: inline;
margin-left: 0 !important;
padding: 0;
}
ul.social-network li {
display: inline;  
}

.social-circle li a {
display: inline-block;
position: relative;
margin: 0 auto 0 auto;
text-align: center;
width: 30px;
height: 30px;
font-size: 15px;
}
.social-circle li img {
width:30px;
margin: 0;
line-height: 30px;
text-align: center;
}

/* color borde - navbar toogler button */
.navbar button:focus {outline: 0;}

/* margen izquierdo icono navbar */
.navbar .navbar-toggler {margin-left: -25px;}

/*CAROUSEL HEADER*/
.carousel-head{
    position: relative;
    width:100%;
}

.slider-header {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    max-height: 400px;
    overflow: hidden;
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, .1);
}  
.slider-header .slides img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.carousel-head .slide img{
    width: 100%;
    height: 100%;  
    object-fit: cover;
}

/*only for ie*/
_:-ms-lang(x), .ie10up, _:-ms-fullscreen, :root .slider-header {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, .1);
}  

_:-ms-lang(x), .ie10up, _:-ms-fullscreen, :root .carousel-head .slide { 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
}

@media (max-width: 991px){
    _:-ms-lang(x), .ie10up, _:-ms-fullscreen, :root header#top .navbar-nav.ml-auto {
            padding-top: 95px;
    }
}

/* BASE */
main{
    min-height: calc(101vh - 65px);
}

/*section*/
section {padding: 20px 0px;height: 100%;background-color: white;}
section.dark{background-color: #231f20;color:white;}
section.full{min-height: 83.7vh;}

.subsection{padding: 15px 15px;}
.column-image img{ width: 100%; max-width: 100%; height: auto; }

/****************************************** SECTIONS ********************************************/

/** contenidos **/
.card {
    border: 0;
}
.secciones {
    display: flex;
    flex-direction:row;
    justify-content:space-around;
    border-bottom: 2px solid #231f20;
    margin-bottom: 20px;
}
.secciones span{font-size: 18px;}

.muestras {
    padding-bottom: 1em;
    border-bottom: 2px solid #231f20;
}

section .contenidos {
    background-color: white;
    text-align: center;
    color:#d83484;
}
section .contenidos .card {
    border:0px;
}
section .contenidos .inner-container {
    padding: 20px 0px;
}
section .contenidos .categoria {
    margin-top: 10px;
}

@media screen and (max-width:576px){
    .categoria {
        margin-top: 10px;
   }
}

.categoria .card-title h2 {
    text-align: center;
    padding: 30px 5px 5px 5px;
    font-weight: bold;
    line-height: 1.2em;
    border-bottom: 1px solid black;
}

@media screen and (max-width:992px){
    iframe{height: 260px;}
}

.fa-play {
    color: white;
    opacity: 1;
}
.title-line{
    border-bottom: 1px solid red;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 100px;
}

main.contenidos .section-container .text-wrapper{
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}
.main-content{
    padding-top: 95px;
    background-color: white;
}
.main-content .container .section-container.header-section{
    padding: 0px 10px 0px 10px;
}
main.contenidos .volver_btn{
    display: flex;
    justify-content: flex-end;
}

main.legal .col-12.col-lg-10.mt-5 { padding-top: 95px; }

main.legal div#cookies_administrar a { word-break: break-all; }
.standard-section{
    padding: 10px;
}

.standard-section .listado { flex-wrap: wrap; }

.listado{
    display: flex;
    margin: 0 auto;
    max-width: 100%;
}

@media screen and (min-width:768px){
    /*contenedor con n items par*/
    .listado.par {
         max-width: 100%; 
    }
}
@media screen and (max-width:992px){
    /*.listado.impar {
         max-width: 70%; 
    }*/
    .listado.impar .poster {
        padding: 5px;
    }
}
@media screen and (min-width:992px){
    .listado.par {
         max-width: 70%; 
    }
}
@media screen and (min-width:1200px){
    .listado.par {
         max-width: 60%; 
    }
    .listado.impar {
        max-width: 80%; 
   }
    
}
@media(min-width: 768px){
    /*par*/
    .listado.par > div {
        flex: 1 1 50%; 
    }
    /*impar*/
    .listado.impar > div {
        flex: 1 1 33%;
    }

}


/* Distribucion Contenidos items */

.parent-poster{
    justify-content: center;
}

 
 /*tamaño de poster - max : ( 280 / 400 ) - aspect ratio: ( 0.7 : 1 ) */
.poster {
    margin:0 auto;
    width: 280px;
    height: 400px;
    overflow: hidden;

    padding-top: 3px;
    padding-bottom: 3px;
}

 /*tamaño poster para resolucion movil*/
 @media(min-width:480px) and (max-width:768px){
    .poster {
        margin:0 auto;
        width: 200px;
        height: 285px;
        overflow: hidden;
        padding: 5px;
    }
 }

 /*tamaño poster para resolucion movil*/
 @media(min-width:768px) and (max-width:992px){
    .impar .poster {
        width: 200px;
        height: 285px;

    }
 }
 
 
.child {
    width: 100%;
    height: 100%;
    background-color: black; /* fallback color */
    background-position: center;
    background-size: cover;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.poster:hover .child,
.poster:focus .child {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}
/* pantalla gris superpuesta */
.child::before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75);
}
.poster:hover .child:before,
.poster:focus .child:before {
    display: block;
}
.poster span {
/*centered text on hover*/display: none;position: absolute;color: white;text-decoration: none;margin: 0 auto;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);transform: translate(-50%, -50%);text-align: center;}
.poster:hover span,
.poster:focus span {
    display: block;
}


/** contacto **/
.contact-section h1{
    text-align: center;
}

.contact_form{
    margin-bottom: 1rem;
}

@media screen and (max-width:991px){
    .contact_form{
        padding-top: 40px;
    }
}
@media screen and (min-width:992px){
    section.contacto .info{
        padding-right: 60px;
    }
}

textarea{
    resize: none;
}


@media screen and (min-width:768px){
    .servicios-imgs .multiple{
        display: flex;
        flex-direction: row;
        align-items:center;
    }
}

@media screen and (max-width:768px){
    .servicios-imgs .multiple{
        flex-direction: column;
    }
}

.servicios-imgs img{
    padding: 5px;
}

/***************************************************************************************/

/*iframe  */
.iframes {
    width: 100%;
    clear: both;
}

.iframe-container{
    margin: 30px 0px;
    height: 100%;
    width: 100%;
}


@media(max-width:768px){
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
      }
       
      .iframe-container iframe {
         border: 0;
         height: 100%;
         left: 0;
         position: absolute;
         top: 0;
         width: 100%;
      }
}

@media(min-width:768px){
    .iframes .iframe-container{
        margin-top: 40px;
        height: 300px;
        padding:10px;
        float: left;
    }
    .iframe-container iframe{
        height: 500px;
    }

    .iframes.multiple .iframe-container{
        width: 50%;
    }

    .iframes .iframe-container iframe{
        height: 100%;
    }
    .iframes .iframe-container .vp-center {
        height: 200px!important;
    }
}

.iframe-container iframe {
    width: 100%;
    
}


.iframe-container iframe, iframe {
    margin:0;
}
    

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
    padding-top: 75%;
}

/**lightbox*/
.lb-close{
    z-index: 999999;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 30px;
    height: 30px;
}
#lightbox .lb-outerContainer{
    width: 100%!important;
    background-color: transparent!important;
}

#lightbox .lb-image{
    margin: 0 auto!important;
}

.lb-disable-scrolling {
    overflow: hidden;
    /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
    /*position: fixed;*/
    height: 100vh;
    width: 100vw;
  }


/* SLIDER */
.slider{
    text-align: center;
    /*height: 340px !important;*/
}
  
  /*tamaño para pantalla movil*/
  @media screen and (max-width:411px){
    .slider{
      height: 220px !important;
    }
    .slider .slide, .slider-single div {
      max-height: 220px;
    }
      
  }
  
  .slider .slide,.slider-single div{
    text-align: center;
    padding: 10px;
    /*height: 340px !important;*/
  }
  
  .slick-slide img, .slick-slide a {
    margin: 0 auto;
    display: block;
  }
  
  .slider .slide .img, .slider .slide a{
    margin: 0;
    padding: 0;
    display: block;
    margin: 0 auto;
    /*height: 340px !important;*/
  }
  
  .slider .slide.img{
    width: auto!important;
  }
  
  .slider .slide .img img{
    width: auto;
    margin: 0 auto;
    /*max-height: 340px !important;*/
  }
  
  /* PLATÓ */
  .plato_item ul{
    margin-top: 10px;
  }

/***FOOTER***/
footer {width: 100%;color:#000;clear: both;font-size:15px;clear: both; text-align:center;}
.fa,
.footer-top {padding:10px 0px 10px 0px; }
.mainfooter a { display:inline-block; color:#000; }
.footer-top .logos{clear:both;margin:0 auto;/*padding:20px 0;font-size:11px;*/}
.footer-top .logos a{display:inline-block;width:97px;height:34px;background:url(../img/footer/logos.png) top left no-repeat #eaeaea;text-indent:100%;overflow:hidden;white-space:nowrap}
.footer-top .logos a.gs{background-position:-582px 0}
.footer-top .logos a.gs:hover{background-position:-582px -34px}
footer .card{padding:0}
.col-subnav a,.subnavigation .nav-link{color:#fff;font-weight:700}
.footer-top{background:#eaeaea;}
.footer-pad{width:100%;word-wrap:break-word}

.form_error {text-align: center;width: 100%;color: yellow;font-size:.85em;}
